import React from "react";
import { Grid, Paper } from "@material-ui/core"
import { ThemeProvider, createMuiTheme, makeStyles } from "@material-ui/core/styles";
import Amplify from "aws-amplify";
import config from "./aws-exports";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AveQSignIn from "./components/user/SignIn";
import SignOut from "./components/user/SignOut";
import Dispatcher from "./components/common/Dispatcher";
import "./app-static/App.css";


// setup amplify (congnito auth)
Amplify.configure(config);

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      display: "none",
    },
  },
  grid: {
    width: "100%",
    margin: "0px",
    padding: "0px",
    textAlign: "center"
  },
  footerContainer: {
    bottom: "0",
    padding: theme.spacing(2),
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.light,
  },
  main: {
    justifyContent: "center",
    padding: theme.spacing(1),
  },
}))

function App() {
  const classes = useStyles()
  const theme = createMuiTheme({
    palette: {
      type: "light",
      primary: {
        main: "#b0bec5",
      },
      secondary: {
        main: "#ffc107",
      }
    },
    typography: {
      useNextVariants: true,
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Paper style={{minHeight: "100vh", width: "100%"}}>
        <Provider store={store}>
          <Router>
            <div>
              <Grid item container justify="center" className={classes.main} xs={12}>
                <Route path="/" exact render={() => <Dispatcher />} />
                <Route path="/signin" render={() => <AveQSignIn />} />
                <Route path="/signout" render={() => <SignOut />} />
              </Grid>
            </div>
          </Router>
        </Provider>
      </Paper>
    </ThemeProvider>
  );
}

export default App;
