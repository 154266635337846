import * as types from "./types";
import { invokeAction } from "./wsActions"
import { addNotification } from "./notificationActions"

export const setStationProfile = (dispatch, stationId, sessionId, properties) => {
  dispatch({
    type: types.SET_STATION_PROFILE,
    payload: {
      stationId: stationId,
      sessionId: sessionId,
      properties: properties
    }
  })
}

export const setAuthorizedStations = (dispatch, stations) => {
  dispatch({
    type: types.SET_AUTHORIZED_STATIONS,
    payload: stations
  })
}

export const editProfile = (dispatch) => {
  dispatch({
    type: types.EDIT_PROFILE,
    payload: null
  })
}

export const updateProfile = (dispatch, section, value, data) => {
  dispatch({
    type: types.UPDATE_PROFILE,
    payload: {
      section: section,
      value: value,
      data: data
    }
  })
}

export const saveProfile = (dispatch, section, value, extra) => {
  dispatch({
    type: types.SAVE_PROFILE,
    payload: {
      section: section,
      ...extra,
      value: value
    }
  })
}

export const cancelProfile = (dispatch) => {
  dispatch({
    type: types.CANCEL_PROFILE
  })
}

export const resetStation = (dispatch) => {
  dispatch({
    type: types.RESET_STATION,
  })
}

export const notifyStationUnread = (dispatch, mail) => {
  dispatch({
    type: types.SET_STATION_UNREAD,
    payload: mail
  })
}

export const getActionables = (dispatch, sessionId) => {
  invokeAction(dispatch, "admin:get-actionables", sessionId, {},
    (handlerDispatch, responseContent) => {
      console.log("Setting actionables: %s items", (responseContent && responseContent.payload.length))
      handlerDispatch({
        type: types.SET_STATION_ACTIONABLES,
        payload: responseContent.payload
      })
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error loading station profile")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    })

  invokeAction(dispatch, "prd:populate-options", sessionId, { actionVar: "allPlaylists"},
    (handlerDispatch, responseContent) => {
      console.log("Setting station playlists: %s items", (responseContent && responseContent.payload.length))
      handlerDispatch({
        type: types.SET_STATION_PLAYLISTS,
        payload: responseContent.payload
      })
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error loading playlists")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    })
}

export const updateProperties = (dispatch, properties, sessionId) => {
  invokeAction(dispatch, "admin:update-properties", sessionId,
    { properties: JSON.stringify(properties) },
    (handlerDispatch, responseContent) => {
      console.log("Response: %s", responseContent.info);
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error updating station properties")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    }
  )
}

export const updateActionable = (dispatch, actionableTemplate, sessionId) => {
  invokeAction(dispatch, "admin:update-actionable", sessionId,
    { actionableTemplate: JSON.stringify(actionableTemplate) },
    (handlerDispatch, responseContent) => {
      console.log("Response: %s", responseContent.length)
      handlerDispatch({
        type: types.UPDATE_ACTIONABLE_TEMPLATE,
        payload: {
          contentId: actionableTemplate.contentId === 0 ? 0 : responseContent.payload[0].contentId,
          actionableTemplate: responseContent.payload[0]
        }
      })
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error updating actionable")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    }
  )
}

export const updateTriggerOption = (dispatch, triggerOption, sessionId) => {
  invokeAction(dispatch, "admin:update-trigger", sessionId,
    { trigger: JSON.stringify(triggerOption) },
    (handlerDispatch, responseContent) => {
      console.log("Response: %s", responseContent.length)
      handlerDispatch({
        type: types.UPDATE_TRIGGER,
        payload: {
          id: triggerOption.id === 0 ? 0 : responseContent.payload[0].id,
          triggerOption: responseContent.payload[0]
        }
      })
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error updating trigger")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    }
  )
}

export const updateActionableCode = (dispatch, actionableCode, sessionId) => {
  invokeAction(dispatch, "admin:update-act-code", sessionId,
    { actionableCode: JSON.stringify(actionableCode) },
    (handlerDispatch, responseContent) => {
      console.log("Response: %s", responseContent.length)
      handlerDispatch({
        type: types.UPDATE_ACT_CODE,
        payload: {
          id: actionableCode.id === 0 ? 0 : responseContent.payload[0].id,
          actionableCode: responseContent.payload[0]
        }
      })
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error updating actionable code")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    }
  )
}

export const addUserAction = (dispatch, userAction, isNew, sessionId) => {
  invokeAction(dispatch, "admin:create-user-action", sessionId,
    { userAction: JSON.stringify(userAction), isNew: `${isNew}` },
    (handlerDispatch, responseContent) => {
      console.log("Response: %s", responseContent.info);
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error creating user action")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    }
  )
}

export const updateMessageTemplate = (dispatch, messageTemplate, sessionId) => {
  invokeAction(dispatch, "admin:update-message-template", sessionId,
    { messageTemplate: JSON.stringify(messageTemplate) },
    (handlerDispatch, responseContent) => {
      console.log("Response: %s", responseContent.info);
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error updating message template")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    }
  )
}

export const reportStationError = (dispatch, error) => {
  dispatch({
    type: types.STATION_ERROR,
    payload: error
  })
}

export const resetStationError = (dispatch) => {
  dispatch({
    type: types.RESET_STATION_ERROR,
  })
}
