import { FETCH_USER_DETAILS } from "../actions/types";

const initialState = {
  isUserSignedIn: false,
  userDetails: null,
  authDetails: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_DETAILS:
      return Object.assign({}, state, {
        userDetails: action.payload.user,
        isUserSignedIn: action.payload.isUserSignedIn,
        lastUpdate: action.payload.lastUpdate,
        authDetails: action.payload.authDetails
      });
    default:
      return state;
  }
}
