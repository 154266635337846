import React, { lazy, Suspense, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import withWidth  from '@material-ui/core/withWidth'
import { initiateConnection } from "../../actions/wsActions"
import { fetchUserDetails } from "../../actions/userDetailsActions"
import { Notification } from "./Notification"
import { Grid } from '@material-ui/core'
import MainMenu from "../common/MainMenu"

const EXPIRATION_PERIOD = 30 * 60 * 1000 // Refetch user details every 30 min

const useStyles = makeStyles(theme => ({
  container: {
    justifyContent: "center",
    maxWidth: "1000px",
    height: "90vh",
  },
}))

function Dispatcher(props) {
  const currentUser = useSelector(state => state.user)
  const signedIn = currentUser && currentUser.isUserSignedIn
  const credentialSaved = localStorage.getItem('aveqToken') !== "" && localStorage.getItem('aveqUserName') != null
  const host = useSelector(state => state.websocket.host)
  const currentView = useSelector(state => state.navigation.currentView)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    if (signedIn && !host) {
      initiateConnection(dispatch)
    }
    else {
      const lastUserUpdate = currentUser && currentUser.lastUpdate
      const since = (lastUserUpdate && (Date.now() - lastUserUpdate)) || Date.now()
      if (credentialSaved && since > EXPIRATION_PERIOD) {
        fetchUserDetails(dispatch, null, {
          userName: localStorage.getItem('aveqUserName'),
          token: localStorage.getItem('aveqToken')
        })
      }
    }
  })

  let components = {
    'station-selector': lazy (() => import('./StationSelector')),
    'channel-selector': lazy (() => import('../chat/routes/ChatWithChannels')),
    'actionable-template-view': lazy (() => import('../admin/routes/ActionableTemplateView')),
    'actionable-template-edit': lazy (() => import('../admin/routes/ActionableTemplateEdit')),
    'trigger-view': lazy (() => import('../admin/routes/TriggerView')),
    'trigger-edit': lazy (() => import('../admin/routes/TriggerEdit')),
    'act-code-view': lazy (() => import('../admin/routes/ShareableCodeView')),
    'act-code-edit': lazy (() => import('../admin/routes/ShareableCodeEdit')),
    'station-properties-view': lazy (() => import('../admin/routes/StationPropertiesView')),
    'station-properties-edit': lazy (() => import('../admin/routes/StationPropertiesEdit')),
    'playlist-view': lazy (() => import('../admin/routes/PlaylistView')),
    'playlist-edit': lazy (() => import('../admin/routes/PlaylistEdit')),
    'user-action-view': lazy (() => import('../admin/routes/UserActionView')),
    'user-action-edit': lazy (() => import('../admin/routes/UserActionEdit')),
    'message-template-view': lazy (() => import('../admin/routes/MessageTemplateView')),
    'message-template-edit': lazy (() => import('../admin/routes/MessageTemplateEdit')),
  }

  const loading = () => console.log("Loading... ")

  let Component = components[currentView.viewUri]

  return (signedIn || credentialSaved) ? (
    <Grid container className={classes.container}>
      <React.Fragment>
        <MainMenu visible={currentView.menu} />
        <Suspense fallback={loading}>
          <Component />
        </Suspense>
        <Notification />
      </React.Fragment>
    </Grid>
  ) : (<Redirect to={"/signin"} />)
}

export default withWidth()(Dispatcher)
