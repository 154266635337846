import * as types from "./types"
import { reconnect } from '../actions/wsActions'
import { userSignOut } from "../actions/userDetailsActions"
import { changeView } from '../actions/viewActions'

const reconnectHandler = (dispatch) => {
  clearNotification(dispatch)
  reconnect(dispatch)
  changeView(dispatch, 'station-selector')
}

const signOutHandler = (dispatch) => {
  clearNotification(dispatch)
  userSignOut(dispatch)
  changeView(dispatch, 'station-selector')
}

const clearHandler = (dispatch, idx) => {
  clearNotification(dispatch, idx)
}

const reconnectAction = { label: "Reconnect", handler: reconnectHandler }
const signOutAction = { label: "Sign Out", handler: signOutHandler }
const clearAction = { label: "Clear", handler: clearHandler }

const actionMapping = {
  "reconnect": reconnectAction,
  "signout": signOutAction,
  "clear": clearAction
}

const severityMapping = {
  "STATION_ERROR": 5,
  "NO_RESPONSE": 2,
  "NO_PINGPONG": 1,
  "CONNECTION_ERROR": 4,
  "CONNECTION_LOST": 5
}

export const addNotification = (dispatch, error) => {
  const updated = (error.actions && error.actions.map(a => typeof(a) === "string" ? actionMapping[a] : a)) || [clearAction]
  const severity = severityMapping[error.code]
  if (!severity) {
    console.log("Invalid error: %s", error.code)
    return
  }
  error.severity = severity

  dispatch({
    type: types.ADD_ERROR,
    payload: Object.assign({}, error, { actions: updated })
  })
}

export const clearNotification = (dispatch, idx) => {
  dispatch({
    type: types.CLEAR_ERROR,
    payload: idx
  })
}
