import { SET_ACTIVE_STATION, SET_CHANNELS, SELECT_ACTIVE_CHANNEL, ADD_MESSAGES_TO_CHANNELS,
  RESET_CHANNEL_COUNT, SET_THREAD_INFO, ADD_NEW_MESSAGE, RESET_CHANNELS } from "../actions/types"
import { getThreadInfo } from "../actions/chatActions"
import { notifyStationUnread } from "../actions/stationActions"

const initialState = {
  currentStationId: 0,
  sessionId: null,
  activeChannelId: null,
  loading: false,
  append: true,
  channels: {},
  channelTree: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_STATION:
      return Object.assign({}, state, {
        currentStationId: action.payload.currentStationId,
        sessionId: action.payload.sessionId,
      })

    case RESET_CHANNELS:
      return Object.assign({}, state, {
        currentStationId: 0,
        sessionId: null,
        activeChannelId: null,
        channels: {},
        channelTree: []
      })

    case SET_CHANNELS:
      let channels = Object.assign({}, state.channels)
      action.payload.forEach((s) => channels[s.id] = {key: s.id, unread: 0, header: s, messages: []})

      return Object.assign({}, state, {
        channels: channels,
        channelTree: buildChannelTree(channels)
      })

    case SELECT_ACTIVE_CHANNEL:
      return Object.assign({}, state, {
        activeChannelId: action.payload,
        loading: true
      })

    case ADD_MESSAGES_TO_CHANNELS:
      let channelId = state.activeChannelId
      channels = mergeChannelMessages(channelId, state.channels, action.payload.content)

      console.log("Setting messages for channelId=%s: %s", channelId, channels[channelId].messages.length)
      return Object.assign({}, state, {
        append: action.payload.append,
        loading: false,
        channels: channels,
      })

    case RESET_CHANNEL_COUNT:
      channels = Object.assign({}, state.channels)
      console.log("Reseting %s: %s", action.payload, state.channels[action.payload].unread)
      if (action.payload != null) {
        channels[action.payload].unread = 0
        return Object.assign({}, state, {
          channels: channels
        })
      }
      return state

    case SET_THREAD_INFO:
      console.log("Updating header for channelId=%s", action.payload.id)
      channels = Object.assign({}, state.channels)
      channels[action.payload.id].header = action.payload
      return Object.assign({}, state, {
        channels: channels
      })

    case ADD_NEW_MESSAGE:
      let mail = action.payload
      if (state.currentStationId !== mail.station_id) {
        console.log("Not for current station %s: %s", state.currentStationId, mail.station_id)
          // station isn't the current one, ignore for now
        notifyStationUnread(action.asyncDispatch, mail)
        return state
      }

      channelId = mail.subject_id
      console.log(`Adding new message to channel ${channelId}`);

      if (state.channels[channelId]) {
        channels = mergeChannelMessages(channelId, state.channels, [mail])
      }
      else {
        channels = Object.assign({}, state.channels)
        getThreadInfo(action.asyncDispatch, state.sessionId, mail.thread_id)
        channels[channelId] = {
          key: channelId,
          unread: 1,
          header: {id: channelId, label: channelId},
          messages: [mail]
        }
      }

      return Object.assign({}, state, {
        append: true,
        channels: channels,
        channelTree: buildChannelTree(channels)
      })

    // Returns the default initial state
    default:
      return state
  }
}

const buildChannelTree = (channels) => {
  const idList = Object.keys(channels).map(id => ({id: id, masterCode: channels[id].header.masterCode}))
  idList.sort((a,b) => {
    // osrt by the order of master code first, and if they're the same
    // sort by thread id, with the shorter comes first
    if (a.masterCode !== b.masterCode) return a.masterCode - b.masterCode
    return b.id - a.id
  })

  return idList.map(x => x.id)
}

const mergeChannelMessages = (channelId, channels, messages) => {
  const copy = Object.assign({}, channels)
  let existingIds = copy[channelId].messages.map(m => m.msg_id)

  const newMessages = messages.filter(m => !existingIds.includes(m.msg_id))
  let refreshedMessages = Array.from(copy[channelId].messages)
  refreshedMessages.push(...newMessages)
  refreshedMessages.sort((a,b) => a.timestamp_ms - b.timestamp_ms)

  if (newMessages.length > 0) {
    let header = Object.assign({}, channels[channelId].header, {
      threadId: refreshedMessages[refreshedMessages.length -1].thread_id
    })
    copy[channelId] = {
      ...channels[channelId],
      header: header,
      messages: refreshedMessages,
      unread: channels[channelId].unread + newMessages.length,
    }
  }
  else {
    copy[channelId] = {
      ...channels[channelId],
      unread: channels[channelId].unread,
    }
  }
  return copy
}
