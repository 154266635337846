import { combineReducers } from "redux"
import stationReducer from "./stationReducer"
import userDetailsReducer from "./userDetailsReducer"
import chatReducer from "./chatReducer"
import wsReducer from "./wsReducer"
import notificationReducer from "./notificationReducer"
import viewReducer from "./viewReducer"

export default combineReducers ({
  stations: stationReducer,
  user: userDetailsReducer,
  chats: chatReducer,
  websocket: wsReducer,
  error: notificationReducer,
  navigation: viewReducer
});
