import { CLOSE_CONNECTION } from "./types"
import { connect, send, disconnect } from "@giantmachines/redux-websocket";
import store from "../store/store";
import { resetStation } from "./stationActions"
import { addNotification, clearNotification } from "./notificationActions"
import { clearView } from "./viewActions"
import { randomStr } from "../utils/utils.js"


const callbacks = {}
let pingpongTime
let pingpongScheduler

console.log("Start watching message responses... ")

setInterval(() => {
	const deleted = []
	for (var ref in callbacks) {
		const handler = callbacks[ref]
		// Timeout after 10 sec
		if (Date.now() - handler.timestamp > 10000) {
			deleted.push(ref)
		}
	}

	if (deleted.length > 0) {
		deleted.forEach(d => {
			if (callbacks[d].label)
				console.log(`No responses for action: ${callbacks[d].label}`)
			delete callbacks[d]
		})

		addNotification(store.dispatch, {
			code: "NO_RESPONSE",
			message: "No responses for some actions",
			actions: [ "clear" ]
	  })
	}

	if (pingpongTime && (Date.now() - pingpongTime > 1000)) {
		addNotification(store.dispatch, {
				code: "NO_PINGPONG",
				message: "No timely signal from the network",
				actions: [ "reconnect", "signout" ]
	  })

		pingpongTime = undefined
	}
}, 100)

function sendMessage(messageType, message, sessionId) {
	message.messageType = messageType;
	if (sessionId)
    message.sessionId = sessionId;
	return send(message, "OCTOPUS");
}

export const initiateConnection = (dispatch) => {
  console.log('Setting up connection to Octopus...')
	if (store.getState().websocket.busy) {
		console.log("Another attempt to connect is in progress. Abort")
	}
	else {
		dispatch(connect("wss://oct.aveq.io/c/s/host", "OCTOPUS"))
	}
}

export const reconnect = (dispatch) => {
	initiateConnection(dispatch)
	resetStation(dispatch)
	clearNotification(dispatch)
	clearView(dispatch)
}

export const closeConnection = (dispatch) => {
  console.log('Disconnecting Octopus connection...')
	if (store.getState().websocket.busy) {
		console.log("The connection is busy. Abort")
	}
	else {
		dispatch(disconnect("OCTOPUS"))
		dispatch({ type: CLOSE_CONNECTION })
		clearTimeout(pingpongScheduler)
		resetStation(dispatch)
		clearNotification(dispatch)
		clearView(dispatch)
	}
}

export const selectActiveStation = (dispatch, station, senderId) => {
  console.log("Connecting to stationId %s as %s at %s", station.key, senderId, Date.now())
	if (station.sessionId && station.sessionId !== "") {
		dispatch(sendMessage("STATION_METRICS", {}, station.sessionId))
	}
	else {
		dispatch(sendMessage("MAKE_HOST", {stationId: station.key, timestamp: Date.now()}, senderId))
	}
}

export const authenticate = (dispatch, token) => {
  dispatch(sendMessage("CONNECT", { token: token }))
}

export const invokeAction = (dispatch, uri, sessionId, params, onResponse, onError = (msg) => { console.log(msg) }, label = null) => {
  const message = {actionUri: uri, params: params, ref: params.ref || randomStr()}

	if (onResponse) {
		callbacks[message.ref] = { onResponse: onResponse, onError: onError, timestamp: Date.now() }
		if (label !== null)
			callbacks[message.ref].label = label
	}

	dispatch(sendMessage('ACTION', message, sessionId))
}

export const pingpong = (dispatch, incomingPingpong) => {
  console.log("PINPONG %s", incomingPingpong && incomingPingpong.value)
	pingpongTime = undefined

  pingpongScheduler = setTimeout(() => {
    dispatch(sendMessage("PINGPONG", { value: (incomingPingpong && (incomingPingpong.value + 1)) || 1 }, null))
		pingpongTime = Date.now()
  }, 30000)
}

export const handleResponse = (dispatch, ref, responseContent) => {
	const handler = callbacks[ref]
	if (handler) {
		handler.onResponse(dispatch, responseContent)
		delete callbacks[ref]
	}
	else {
		console.log("Unable to locate a handler to handle message ref %s", ref)
	}
}

export const handleErrorResponse = (dispatch, ref, responseContent) => {
	const handler = callbacks[ref]
	if (handler) {
		handler.onError(dispatch, responseContent)
		delete callbacks[ref]
	}
	else {
		console.log("Unable to locate a handler to handle error ref %s", ref)
	}
}
