import React, { useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import aveq_logo from "../../app-static/aveq_logo_1.svg";
import { userSignOut } from "../../actions/userDetailsActions";
import SlideMenu from "../../components/common/SlideMenu"
import { Grid, Typography, Hidden, AppBar, Toolbar, Avatar, List, ListItem, ListItemText, Popover } from "@material-ui/core"
import { resetChannels } from "../../actions/chatActions"
import { changeView } from "../../actions/viewActions"
import { closeConnection } from "../../actions/wsActions"
import { resetStation } from "../../actions/stationActions"

const useStyles = makeStyles(theme => ({
  appBar: {
    gridArea: "app-bar",
    width: "100%",
    height: "56px",
    backgroundColor: theme.palette.primary.dark
  },
  toolBar: {
    width: "90vw",
    margin: "0 auto",
  },
  toolBarContainer: {
    display: "flex",
    width: "100%",
    alignItems: "inherit",
  },
  buttonText: {
    textAlign: "center"
  },
  signedinMessage: {
  },
  logo: {
    display: "block",
    width: "90px",
    left: "-15px"
  },
  toggleProfile: {
    display: "none"
  },
  logoList: {
    width: "90px"
  },
  userProfile: {
    textAlign: "center"
  },
  popupContainer: {
    wordWrap: "break-word",
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}))

function MainMenu(props) {
  const { visible } = props
  const user = useSelector(state => state.user.userDetails)
  const isUserSignedIn = useSelector(state => state.user.isUserSignedIn)
  const station = useSelector(state => state.stations.list[state.stations.currentStationId])
  const dispatch = useDispatch()

  const [ anchorEl, setAnchorEl ] = React.useState(null)
  const profileRef = useRef()
  const open = Boolean(anchorEl)
  const id = open ? "profile-popover" : undefined;

  const switchStation = () => {
    changeView(dispatch, 'station-selector')
    resetChannels(dispatch)
    resetStation(dispatch)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const updateProfile = () => {
    //updateUserProfile(dispatch, { "first_name": "Jim", "last_name": "Smith"})
    setAnchorEl(null)
  }

  const signOut = () => {
    userSignOut(dispatch)
    closeConnection(dispatch)
    setAnchorEl(null)
  }

  const showProfile = (isLoggedIn, classes) => {
    if (isLoggedIn) {
      return (
        <List>
          <ListItem button onClick={() => {
              changeView(dispatch, 'actionable-template-view')
            } }>
            <ListItemText primary="Store profile" className={classes.buttonText} />
          </ListItem>
        </List>
      )
    }
    return <React.Fragment></React.Fragment>
  }

  const classes = useStyles()

  if (!visible) return (<React.Fragment></React.Fragment>)

  return (
    <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.toolBarContainer}>
            <Grid container item xs={12}>
            <Grid item xs={2}>
            <Avatar className={classes.logo} onClick={switchStation} src={aveq_logo}/>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h8" className={classes.signedinMessage}>
                {
                  (station)
                  ? (<React.Fragment><div>{station.station.name}</div><div>{station.station.address}</div></React.Fragment>)
                  : <React.Fragment></React.Fragment>
                }
              </Typography>
            </Grid>
            <Grid container item xs={3}>
            <Grid item>
              <Hidden xsDown>
                <List>
                <ListItem button onClick={() => {
                    changeView(dispatch, 'channel-selector')
                  } }>
                  <ListItemText primary="Chat" className={classes.buttonText} />
                </ListItem>
                </List>
              </Hidden>
            </Grid>
            <Grid item>
              <Hidden xsDown>
                {showProfile(isUserSignedIn, classes)}
              </Hidden>
            </Grid>
            </Grid>
            <Grid item xs={1}>
              <Hidden xsDown>
                  {
                    (isUserSignedIn)
                    ? (
                      <React.Fragment>
                        <Avatar ref={profileRef} className={classes.userProfile}
                          onClick={() => setAnchorEl(profileRef.current)}
                        >
                          {user.username}
                        </Avatar>
                        <Popover
                          open={open}
                          id={id}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                        <List>
                          <ListItem className={classes.popupContainer} key={0} onClick={updateProfile} >
                            <ListItemText className={classes.popupText} primary={"Profile"} />
                          </ListItem>
                          <ListItem className={classes.popupContainer} key={1} onClick={signOut} >
                            <ListItemText className={classes.popupText} primary={"Sign out"} />
                          </ListItem>
                        </List>
                        </Popover>
                      </React.Fragment>
                    )
                    : <React.Fragment></React.Fragment>
                  }
              </Hidden>
              <Hidden smUp>
                <SlideMenu />
              </Hidden>
            </Grid>
            </Grid>
          </div>
        </Toolbar>
    </AppBar>
  )
}

export default MainMenu
