import { SET_ACTIVE_STATION, SET_CHANNELS, SELECT_ACTIVE_CHANNEL, ADD_MESSAGES_TO_CHANNELS,
  RESET_CHANNEL_COUNT, SET_THREAD_INFO, ADD_NEW_MESSAGE, RESET_CHANNELS } from "./types"
import { invokeAction } from "./wsActions"
import { addNotification } from "./notificationActions"


export const sendUserMessage = (dispatch, sessionId, channel, text) => {
  console.log("Sending message %s %s", text, Date.now());
  invokeAction(dispatch, "messenger:send", sessionId,
    {
      recipientId: channel.header.masterCode,
      threadId: channel.header.threadId,
      messageType: "text",
      message: text,
      asHost: true,
      timestamp_ms: `${Date.now()}`
    },
    (handlerDispatch, responseContent) => {
      console.log("Response: %s", responseContent.info);
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error sending message")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    }
  )
}

export const sendActionable = (dispatch, sessionId, channel, contentId) => {
  console.log("Sending actionable %s", contentId);
  invokeAction(dispatch, "messenger:act", sessionId,
    {
      recipientId: channel.header.masterCode,
      threadId: channel.header.threadId,
      contentId: contentId,
      timestamp_ms: `${Date.now()}`
    },
    (handlerDispatch, responseContent) => {
      console.log("Response: %s", responseContent.info);
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error sending actionables")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    }
  )
}

export const setActiveStation = (dispatch, stationId, sessionId) => {
  // get the list of ongoing channels for the station, set station's channels
  invokeAction(dispatch, "messenger:message-counts", sessionId, {},
    (handlerDispatch, responseContent) => {
      console.log("Setting channels: %s", responseContent.payload && responseContent.payload.length);
      handlerDispatch({
        type: SET_CHANNELS,
        payload: responseContent.payload
      })
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error gettting message channels")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: ["clear"]
      })
    }
  )

  dispatch({
    type: SET_ACTIVE_STATION,
    payload: {
      currentStationId: stationId,
      sessionId: sessionId
    }
  })
}

export const selectActiveChannel = (dispatch, sessionId, channelId) => {
  console.log("Getting messages for subjectId %s", channelId);
  dispatch({
    type: SELECT_ACTIVE_CHANNEL,
    payload: channelId
  })

  invokeAction(dispatch, "messenger:subject-messages", sessionId, { subjectId: channelId },
    (handlerDispatch, responseContent) => {
      console.log("Setting thread messages: %s", responseContent && responseContent.length);
      handlerDispatch({
        type: ADD_MESSAGES_TO_CHANNELS,
        payload: {
          content: responseContent,
          append: true
        }
      })
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error reading thread messages")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    },
    `Thread messages for ${channelId}`
  )
}

export const retrieveHistoryMessages = (dispatch, sessionId, channelId, threadId) => {
  console.log("Getting historical messages for subjectId %s", channelId);
  dispatch({
    type: SELECT_ACTIVE_CHANNEL,
    payload: channelId
  })

  invokeAction(dispatch, "messenger:subject-messages", sessionId, { subjectId: channelId, threadId: threadId },
    (handlerDispatch, responseContent) => {
      console.log("Setting thread messages: %s", responseContent && responseContent.length);
      handlerDispatch({
        type: ADD_MESSAGES_TO_CHANNELS,
        payload: {
          content: responseContent,
          append: false
        }
      })
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error reading thread messages")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    },
    `Thread messages for ${channelId}`
  )
}

export const resetChannelCount = (dispatch, channelId) => {
  dispatch({
    type: RESET_CHANNEL_COUNT,
    payload: channelId
  })
}

export const getThreadInfo = (dispatch, sessionId, channelId) => {
  invokeAction(dispatch, "messenger:thread-info", sessionId, { threadId: channelId },
    (handlerDispatch, responseContent) => {
      handlerDispatch({
        type: SET_THREAD_INFO,
        payload: responseContent.payload[0]
      })
    },
    (handlerDispatch, errorResponseContent) => {
      console.log("Error getting thread header")
      addNotification(handlerDispatch, {
          code: "STATION_ERROR",
          message: errorResponseContent.errorMessage,
          actions: [ "clear"]
      })
    }
  )
}

export const addNewMessage = (dispatch, message) => {
  dispatch({
    type: ADD_NEW_MESSAGE,
    payload: message
  })
}

export const resetChannels = (dispatch) => {
  dispatch({
    type: RESET_CHANNELS,
  })
}
