export const OCTOPUS_CONNECTING = "OCTOPUS::CONNECT"

export const OCTOPUS_CONNECTION_OPEN = "OCTOPUS::OPEN"

export const OCTOPUS_MESSAGE_SENT = "OCTOPUS::SEND"

export const OCTOPUS_MESSAGE_RECEIVED = "OCTOPUS::MESSAGE"

export const OCTOPUS_CONNECTION_BROKEN = "OCTOPUS::BROKEN"

export const OCTOPUS_CONNECTION_CLOSED = "OCTOPUS::CLOSED"

export const OCTOPUS_ERROR = "OCTOPUS::ERROR"

export const CLOSE_CONNECTION = "CLOSE_CONNECTION"

export const SET_STATION_PROFILE = "SET_STATION_PROFILE"

export const SET_AUTHORIZED_STATIONS = "SET_AUTHORIZED_STATIONS"

export const SET_STATION_ACTIONABLES = "SET_STATION_ACTIONABLES"

export const SET_STATION_PLAYLISTS = "SET_STATION_PLAYLISTS"

export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS"

export const USER_SIGN_IN = "USER_SIGN_IN"

export const USER_SIGN_OUT = "USER_SIGN_OUT"

export const SET_ACTIVE_STATION = "SET_ACTIVE_STATION"

export const SET_STATION_UNREAD = "SET_STATION_UNREAD"

export const SET_CHANNELS = "SET_CHANNELS"

export const ADD_MESSAGES_TO_CHANNELS = "ADD_MESSAGES_TO_CHANNELS"

export const SELECT_ACTIVE_CHANNEL = "SELECT_ACTIVE_CHANNEL"

export const RESET_CHANNEL_COUNT = "RESET_CHANNEL_COUNT"

export const SET_THREAD_INFO = "SET_THREAD_INFO"

export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE"

export const CHANGE_VIEW = "CHANGE_VIEW"

export const EDIT_PROFILE = "EDIT_PROFILE"

export const UPDATE_PROFILE = "UPDATE_PROFILE"

export const SAVE_PROFILE = "SAVE_PROFILE"

export const CANCEL_PROFILE = "CANCEL_PROFILE"

export const RESET_CHANNELS = "RESET_CHANNELS"

export const RESET_STATION = "RESET_STATION"

export const STATION_ERROR = "STATION_ERROR"

export const RESET_STATION_ERROR = "RESET_STATION_ERROR"

export const ADD_ERROR = "ADD_ERROR"

export const CLEAR_ERROR = "CLEAR_ERROR"

export const CLEAR_VIEW = "CLEAR_VIEW"

export const UPDATE_ACTIONABLE_TEMPLATE = "UPDATE_ACTIONABLE_TEMPLATE"

export const UPDATE_TRIGGER = "UPDATE_TRIGGER"

export const UPDATE_ACT_CODE = "UPDATE_ACT_CODE"
