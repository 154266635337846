import { OCTOPUS_CONNECTING, OCTOPUS_CONNECTION_OPEN, OCTOPUS_MESSAGE_RECEIVED, OCTOPUS_ERROR,
  OCTOPUS_CONNECTION_BROKEN, OCTOPUS_CONNECTION_CLOSED, OCTOPUS_MESSAGE_SENT, CLOSE_CONNECTION } from "../actions/types"
import { authenticate, handleResponse, handleErrorResponse, pingpong } from "../actions/wsActions"
import { getActionables, setAuthorizedStations, setStationProfile } from "../actions/stationActions"
import { setActiveStation, addNewMessage } from "../actions/chatActions"
import { addNotification } from "../actions/notificationActions"
import { truncateStr } from "../utils/utils.js"

const initialState = {
  host: null,
  busy: false,
  connectedTimestamp: null,
  disconnectedTimestamp: null,
}

const chunkRepo = {}

export default function(state = initialState, action) {
  switch (action.type) {
    case OCTOPUS_CONNECTING:
      return Object.assign({}, state, {
        connectedTimestamp: action.meta.timestamp,
        busy: true
      })

    case OCTOPUS_CONNECTION_OPEN:
      return Object.assign({}, state, {
        connectedTimestamp: action.meta.timestamp,
        busy: false
      })

    case CLOSE_CONNECTION:
      return Object.assign({}, state, {
        host: null,
      })

    case OCTOPUS_MESSAGE_RECEIVED:
      return Object.assign({}, state, processMessage(action, state, action.payload))

    case OCTOPUS_MESSAGE_SENT:
      return Object.assign({}, state, {
        messageSent: action.payload,
      })

    case OCTOPUS_ERROR:
      console.log("Error occured with websocket connection %s", action.payload.message)
      addNotification(action.asyncDispatch, {
          code: "CONNECTION_ERROR",
          message: action.payload.message,
          actions: []
      })
      return state

    case OCTOPUS_CONNECTION_BROKEN:
      console.log("Connection broken");
      addNotification(action.asyncDispatch, {
          code: "CONNECTION_ERROR",
          message: "Connection is broken",
          actions: []
      })
      return state

    case OCTOPUS_CONNECTION_CLOSED:
      console.log("Connection closed %s", JSON.stringify(action.payload))

      // create an error notification if connection is closed during a live session
      if (state.host) {
        addNotification(action.asyncDispatch, {
            code: "CONNECTION_LOST",
            message: "Connection is lost",
            actionRequired: true,
            actions: [ "reconnect" ]
        })
      }

      return Object.assign({}, state, {
        disconnectedTimestamp: action.meta.timestamp
      })

    default:
      return state
  }
}

const processMessage = (action, state, messagePayload) => {
  console.log("octopus> %s", truncateStr(messagePayload.message, 512))
  let messageContent = JSON.parse(messagePayload.message)

  switch (messageContent.messageType) {
    case "CONNECTED":
      // Send a connect request back to octopus
      // if no token is passed octopus will return an annonymous one
      authenticate(action.asyncDispatch, localStorage.getItem('aveqToken'))
      return {};

    case "AUTHENTICATED":
      const { participant } = messageContent
      // Set the userId and token in localStorage
      localStorage.setItem("aveqId", participant.userProfile.user.user_id)
      localStorage.setItem("aveqToken", participant.userProfile.credentials.token)

      // Initiating keep alive
      pingpong(action.asyncDispatch)
      return { host: participant, pingpongTime: Date.now() }

    case "STATION_METRICS":
      const { sessionId, metrics } = messageContent;
      console.log("Received station metrics: %s", truncateStr(JSON.stringify(metrics)))

      getActionables(action.asyncDispatch, sessionId);

      setStationProfile(action.asyncDispatch,
        metrics.station.station.station_id, sessionId,
        metrics.stationProperties)

      setActiveStation(action.asyncDispatch, metrics.station.station.station_id, sessionId)

      return {}

    case "PAYLOAD_MESSAGE":
      switch (messageContent.ref) {
        case "authorized_stations":
          setAuthorizedStations(action.asyncDispatch, messageContent.payload)
          return {}

        default:
          handleResponse(action.asyncDispatch, messageContent.ref, messageContent)
          return {}
      }

    case "CHUNK_MESSAGE":
      const payloadStr = readChunks(messageContent)
      if (!payloadStr) {
        return {}
      }

      handleResponse(action.asyncDispatch, messageContent.ref, JSON.parse(payloadStr))
      return {}

    case "INFO":
      handleResponse(action.asyncDispatch, messageContent.ref, messageContent)
      return {}

    case "MAIL":
      let mail = messageContent.mail
      console.log("Adding message %s", truncateStr(JSON.stringify(mail), 256), 0)
      addNewMessage(action.asyncDispatch, mail)
      return {}

    case "ERROR":
      if (messageContent.ref && messageContent.ref !== "") {
        handleErrorResponse(action.asyncDispatch, messageContent.ref, messageContent)
      }
      else {
        addNotification(action.asyncDispatch, {
          code: "STATION_ERROR",
          message: messageContent.errorMessage,
          timestamp: Date.now(),
          type: "Error",
          actions: []
        })
      }
      return {}

    case "PINGPONG":
      pingpong(action.asyncDispatch, messageContent)
      return {}

    default:
      return {}
  }
}

const readChunks = (messagePayloadJson) => {
  let chunks = chunkRepo[messagePayloadJson.ref]
  if (!chunks) {
    chunks = []
    chunkRepo[messagePayloadJson.ref] = chunks
  }
  chunks.push(messagePayloadJson)

  if (chunks.length === messagePayloadJson.total) {
      console.log("All chunks read %s: %s", messagePayloadJson.ref, chunks.length)
      chunks.sort((a,b) => a.index - b.index)
      const payloadStr = atob(chunks.map(c => c.payloadStr).join(""))
      delete chunkRepo[messagePayloadJson.ref]
      return payloadStr
  }
}
