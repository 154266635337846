import React, { useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core"
import { clearNotification } from "../../actions/notificationActions"
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    action: {
      backgroundColor: theme.palette.grey[600],
      spacing: theme.spacing[1]
    },
}))


export function Notification(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const classes = useStyles()
  const errors = useSelector(state => state.error.errors)
  const dispatch = useDispatch()
  const {sm} = props // for simulation

  const handleClose = (key, handler) => {
    clearNotification(dispatch, key)
    closeSnackbar(key)
    handler(dispatch, key)
  }

  const action = key => (
      <React.Fragment>
      {errors[key].actions.map(action =>
          <Button className={classes.action} onClick={() => { handleClose(key, action.handler) }}>
              {action.label}
          </Button>
      )}
      </React.Fragment>
  )

  useEffect(() => {
    if ((errors && Object.keys(errors).length > 0) || (sm && sm === 1)) {
      const k = Array.from(Object.keys(errors))
      k.sort((k1, k2) => errors[k2].severity - errors[k1].severity)
      enqueueSnackbar(errors[k[0]].message, {
        key: k[0],
        persist: true,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        action
      })
    }
  })

  return (<div>
    </div>)
}
