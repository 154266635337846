// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:0d145758-2eb5-4456-9346-101c29a9e747",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_4HZpfwjJu",
    "aws_user_pools_web_client_id": "1ba8atqfic854ltdkgugbpfpdd"
};


export default awsmobile;
