import * as types from "./types";
import { Auth } from "aws-amplify";

export const legacyFetchUserDetails = (props, user) => (dispatch) => {
  fetchUserDetails(dispatch, user, props.authDetails)
};

export const fetchUserDetails = (dispatch, user, authDetails) => {
  console.log("Fetching user details");

  // newly authenticated user, lets store user in state
  if (user && Object.getPrototypeOf(user).toString() === '[object Object]') {
    // TODO : this shit is crazy...basically allowing "user" to be overloaded
    // it can either be valid user object or valid authDetails object
    // will need to make this cleaner
    dispatch({
      type: types.FETCH_USER_DETAILS,
      payload: {
        user: user.token !== undefined && user.token !== "" ? null : user,
        isUserSignedIn: user.token !== undefined && user.token !== "" ? false : true,
        authDetails: user.token !== undefined && user.token !== "" ? user : authDetails || {},
        lastUpdate: Date.now()
      }
    })
  } else {
    // check if user has current session
    Auth.currentSession()
    .then(data => {
      dispatch({
        type: types.FETCH_USER_DETAILS,
        payload: {
          user: {
            username: data.idToken.payload["cognito:username"],
            attributes: data.idToken.payload
          },
          isUserSignedIn: true,
          authDetails: authDetails || {},
          lastUpdate: Date.now()
        }
      })
    })
    // user is not logged in, no need to do anything, user details is empty
    .catch(err => {
      localStorage.removeItem('aveqUserName');
      localStorage.removeItem('aveqToken');
      console.log(err)
    });
  }
};

export const legacyGetUserToken = (props, cb) => (dispatch) => {
  getUserToken(dispatch, props, cb)
};

export const getUserToken = (dispatch, props, cb) => {
  if (localStorage.getItem('aveqToken') === "" || localStorage.getItem('aveqUserName') == null) {
    console.log("do not have aveq token, fetch new one from api...");
    // aveq api get Auth Token
    const aveqGetAuthTokenURL = "https://api.aveq.io/u/anonymous"
    fetch(aveqGetAuthTokenURL, {
      method: 'POST',
      'Content-Type': 'application/json',
      body: null,
    })
      .then(response => {
        if (!response.ok) {
          throw(new Error("failed to get aveq auth token : " + response.status));
        }
        console.log(response);
        return response.json()
      })
      .then(data => {
        if (data.entity && data.entity.user) {
          if (data.entity && data.entity.credentials) {
            var userName = data.entity.user.user_name;
            var aveqToken = data.entity.credentials.token;
            console.log("User retrieved: %s %s", userName, aveqToken)
          } else {
            throw(new Error("failed to get aveq token from api"));
          }
        } else {
          throw(new Error("failed to get aveq anonymous username from api"));
        }
        dispatch({
          type: types.FETCH_USER_DETAILS,
          payload: {
            userDetails: props.userDetails || {},
            isUserSignedIn: props.isUserSignedIn || false,
            authDetails: {
              userName,
              token: aveqToken
            }
          }
        });
        localStorage.setItem('aveqUserName', userName);
        localStorage.setItem('aveqToken', aveqToken);
        cb(null, {
          userName,
          token: aveqToken
        });
      })
      .catch(error => cb(error, null));
  } else {
    console.log(`aveq token located : [${localStorage.getItem('aveqUserName')}]`)
    dispatch({
      type: types.FETCH_USER_DETAILS,
      payload: {
        userDetails: props.userDetails || {},
        isUserSignedIn: props.isUserSignedIn || false,
        authDetails: {
          userName: localStorage.getItem('aveqUserName'),
          token: localStorage.getItem('aveqToken')
        }
      }
    });
    cb(null, {
      userName: localStorage.getItem('aveqUserName'),
      token: localStorage.getItem('aveqToken')
    });
  }
};

export const legacyUserSignOut = () => (dispatch) => {
  userSignOut(dispatch)
}

export const userSignOut = (dispatch) => {
  // clear local storage
  localStorage.removeItem('aveqUserName');
  localStorage.removeItem('aveqToken')
  // Reset the user details in the store to null
  dispatch({
    type: types.FETCH_USER_DETAILS,
    payload: {
      user: null,
      isUserSignedIn: false,
      authDetails: {}
    }
  });
};
