import { CHANGE_VIEW, CLEAR_VIEW } from "../actions/types"

const defaultView = { viewUri: 'station-selector', menu: false }

const initialState = {
  currentView: defaultView
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_VIEW:
      console.log("Changing view to %s", JSON.stringify(action.payload))
      return Object.assign({}, state, {
        currentView: action.payload,
      })

    case CLEAR_VIEW:
      console.log("Clearing view to default")
      return Object.assign({}, state, {
        currentView: defaultView,
      })

    default:
      return state
  }
}
