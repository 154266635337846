import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchUserDetails } from "../../actions/userDetailsActions";
import { Link } from 'react-router-dom';
import { 
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  Button,
  IconButton,
  Divider
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import SlideTransition from "./SlideTransition"


const useStyles = (themes) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: themes.spacing(2),
    flex: 1,
  },
  menuIcon: {
    transform: "scale(1.5)",
    color: "#fff"
  }
});

class FullScreenDialog extends Component {
  state = {
    isOpen: false,
  }

  componentDidMount() {
    //this.props.fetchUserDetails();
  }

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  showProfile = (isLoggedIn) => {
    if (isLoggedIn) {
      return (
        <ListItem button component={Link} to="/userProfile" onClick={this.toggleOpen}>
          <ListItemText primary="Profile" secondary="Display Profile Information" />
        </ListItem>
      )
    }
    return <React.Fragment></React.Fragment>
  }

  render() {
    const { classes } = this.props;
    const { isOpen } = this.state;
    let displayProfile = this.showProfile(this.props.isUserSignedIn)
    return (
      <div>
        <Button onClick={this.toggleOpen}>
          <MenuOpenIcon className={classes.menuIcon} />
        </Button>
        <Dialog fullScreen open={isOpen} onClose={this.toggleOpen} TransitionComponent={SlideTransition}>
          <AppBar className={classes.appBar} color="primary">
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Options
              </Typography>
              <IconButton edge="end" color="#fff" onClick={this.toggleOpen} aria-label="close">
                <CloseIcon className={classes.menuIcon} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <List>
            {
              (this.props.isUserSignedIn)
              ? <ListItem button component={Link} to="/signout">
                <ListItemText primary={this.props.user ? `${this.props.user.username}` : ""} secondary="Logout" />
              </ListItem>
              : <ListItem button component={Link} to="/signin" onClick={this.toggleOpen}>
                  <ListItemText primary="Login" secondary="sign in or create an account" />
              </ListItem>
            }
            <ListItem button component={Link} to="/chat" onClick={this.toggleOpen}>
              <ListItemText primary="Chat" secondary="Chat with Businesses" />
            </ListItem>
            <Divider />
            {displayProfile}
          </List>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
  isUserSignedIn: state.user.isUserSignedIn,
});

export default connect(
  mapStateToProps,
  { fetchUserDetails }
)(withStyles(useStyles)(FullScreenDialog));