import { ADD_ERROR, CLEAR_ERROR } from "../actions/types";
//import { updateProperties, updateActionable, addUserAction, updateMessageTemplate } from "../actions/stationActions"

// each error should have format:
// { code, message, timestamp, type (error, warning, information), actions[] }
// action format: { label, handler }
const initialState = {
  errors: {}
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_ERROR:
      let newErrors = Object.assign({}, state.errors)

      const err = action.payload
      if (err.code && err.code !== "")
        newErrors[err.code] = action.payload

      return Object.assign({}, state, {
        errors: newErrors
      })


    case CLEAR_ERROR:
      newErrors = Object.assign({}, state.errors)
      const key = action.payload
      if (key && key !== "")
        delete newErrors[key]
      else
        newErrors = {}

      return Object.assign({}, state, {
        errors: newErrors
      })

    default:
      return state
  }
}
