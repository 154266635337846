import React from 'react';
import { SignIn } from "aws-amplify-react";
import { Auth } from "aws-amplify";
import Amplify from "aws-amplify";
import config from "../../aws-exports";
import { connect } from "react-redux";
import Avatar from '@material-ui/core/Avatar';
import { Redirect } from "react-router-dom";
import { legacyFetchUserDetails as fetchUserDetails, legacyGetUserToken as getUserToken } from "../../actions/userDetailsActions";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import aveqLogo from '../../app-static/aveq_logo_2.svg';

// setup Amplify
Amplify.configure(config);

// display copyright footer
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://aveq.io/">
        aveq inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// css classes
const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: "45vw",
    height: "45vw",
    maxHeight: "400px"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class AveQSignIn extends SignIn {
  _validAuthStates = ["signIn", "signedOut", "signedUp"];
  state = {
    displayAlert: false,
    redirect: false,
    signUp: false,
    forgotPassword: false,
    forgotPasswordSubmit: false,
    confirmSignUp: false,
    signUpResult: {}
  };

  componentDidMount() {
    // keep track mounted state
    this._isMounted = true;
  }

  componentWillUnmount() {
    // ensure property is updated when unmount occurs
    this._isMounted = false;
  }

  // submit handler
  handleFormSubmission = (evt) => {
    evt.preventDefault();
    if (this.state.confirmSignUp) {
      this.confirmSignUp()
    } else if (this.state.signUp) {
      this.accountSignUp()
    } else {
      this.signIn()
    }
  }

  // when we need to call something but don't want to do anything
  noop = () => {}

  // login to aws cognito handler
  signIn = async () => {
    const username = this.inputs.username;
    const password = this.inputs.password;
    try {
      await Auth.signIn(username, password)
        .then((user) => {
          console.log("user");
          console.log(user);
          Auth.currentAuthenticatedUser()
            .then(user => Auth.userAttributes(user))
            .then(attributes => {
              console.log("attributes: %s", JSON.stringify(attributes));
              var sub = attributes.filter(el => el.Name === "sub")
              console.log("Sub : ", sub[0].Value)
              user.attributes = attributes
              this.props.getUserToken(this.props, (err, authDetails) => {
                if (err !== null) {
                  this.triggerAlert("error", "Login failed", {redirect: true})
                  console.log(err);
                }
                this.props.fetchUserDetails(this.props, user);
                var aveqCognitoLookupURL = "https://api.aveq.io/u/cognito/lookup"
                fetch(aveqCognitoLookupURL, {
                  method: 'GET',
                  'Content-Type': 'application/json',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${btoa(localStorage.getItem('aveqToken'))}`,
                    'AVEQ_COGNITO_SUB': sub[0].Value
                  }
                })
                .then(response => {
                  if (!response.ok) {
                    this.triggerAlert("error", "Login failed");
                    throw(new Error(response));
                  }
                  console.log("RESPONSE: %s", JSON.stringify(response));
                  return response.json()
                })
                .then(data => {
                  if (data.entity && data.entity.user) {
                    if (data.entity && data.entity.credentials) {
                      var userId = data.entity.user.user_id;
                      var userName = data.entity.user.user_name;
                      var aveqToken = data.entity.credentials.token;
                      localStorage.setItem('aveqId', userId);
                      localStorage.setItem('aveqUserName', userName);
                      localStorage.setItem('aveqToken', aveqToken);
                      this.props.fetchUserDetails(this.props, (err, authDetails) => {
                        // TODO : this is a noop
                      });
                    } else {
                      throw(new Error("failed to get aveq token from api"));
                    }
                  } else {
                    throw(new Error("failed to get aveq anonymous username from api"));
                  }
                  this.triggerAlert("success", "Login sucessful", {redirect: true})
                })
                .catch(err => {
                  // TODO : need to figure out how to fail login...don't allow user to login
                  // Call signout function to get them logged out
                  console.log(err);
                  this.triggerAlert("error", "Login failed : " + err);
                })
              })
            })
            .catch(err => console.log(err));
        });
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        this.props.updateUsername(username);
        await Auth.resendSignUp(username);
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
        this.triggerAlert("error", "Login failed. Incorrect username or password")
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
        this.triggerAlert("error", `${username} does not exist, please try a differnt username`)
      } else {
        this.triggerAlert("error", "an unknown login error has occurred...please try again")
        console.error(err);
      }
    }
  }

  // create new cognito account handler
  accountSignUp = async () => {
    const username = this.inputs.username;
    const password = this.inputs.password;
    const email = this.inputs.email;
    const aveqgetAuthTokenURL = "https://api.aveq.io/u/anonymous"
    var aveqToken;
    // call the aveq api to get token before calling signUP
    // only if the token doesn't already exist
    if (localStorage.getItem('aveqUserName') !== null && localStorage.getItem('aveqToken') !== null) {
      aveqToken = localStorage.getItem('aveqToken');
    } else {
      console.log("we don't have an auth token so we will try to get from aveq api");
      // TODO : replace this fetch with a call to user actions to get token
      fetch(aveqgetAuthTokenURL, {
        method: 'POST',
        'Content-Type': 'application/json',
        body: null
      })
      .then(response => {
        if (!response.ok) {
          throw(new Error("failed to get aveq auth token : " + response.status));
        }
        return response.json();
      })
      .then(async (data) => {
        if (data.entity && data.entity.user) {
          if (data.entity && data.entity.credentials) {
            aveqToken = data.entity.credentials.token;
            localStorage.setItem("aveqId", data.entity.user.user_id);
            localStorage.setItem('aveqUserName', data.entity.user.user_name);
            localStorage.setItem('aveqToken', aveqToken);
            this.props.fetchUserDetails(this.props, {
              userName: data.entity.user.user_name,
              token: aveqToken
            })
            try {
              const user = await Auth.signUp({
                username,
                password,
                attributes : {
                  email,
                  'custom:aveq:token': aveqToken
                }
              })
              console.log(user);
              this.inputs.password = ""
              this.triggerAlert("success", "successfully signed up for an account",{
                confirmSignUp: true,
                signUpResult: user
              })
            } catch (error) {
              console.log('error signing up:', error);
              this.triggerAlert("error", error.message)
            }
          } else {
            throw(new Error("failed to get aveq token from api"));
          }
        } else {
          throw(new Error("failed to get aveq anonymous username from api"));
        }
      })
    }
  }

  // verify email handler
  confirmSignUp = async () => {
    const username = this.inputs.username;
    const code = this.inputs.code;
    try {
      await Auth.confirmSignUp(username, code);
      console.log("successfully verified email address")
      this.triggerAlert("success", "successfully verified email address", {
        redirect: false,
        signUp: false,
        forgotPassword: false,
        forgotPasswordSubmit: false,
        confirmSignUp: false
      })
      this.inputs.code = ""
    } catch (error) {
      console.log('error confirming sign up', error);
      this.triggerAlert("error", error.message)
    }
  }

  // resend verification code handler
  resendConfirmationCode = async () => {
    const username = this.inputs.username;
    try {
      await Auth.resendSignUp(username);
      console.log('code resent succesfully');
      this.triggerAlert("success", "verification code has been resent to your email address")
    } catch (error) {
      console.log('error resending code:', error)
      this.triggerAlert("error", error.message)
    }
  }

  // forgot password handler
  forgotPassword = (evt) => {
    evt.preventDefault();
    const username = this.inputs.username;
    Auth.forgotPassword(username)
      .then((data) => {
        console.log(data)
        // if data has code prop then something went wrong and we should throw
        // need to check this because all errors don't make it to catch block
        if (data.code) {
        // send errors to catch block
         throw(data)
        }
        this.triggerAlert("success", "change password code has been sent to your email address", {
          forgotPassword: false,
          forgotPasswordSubmit: true
        })
      })
      .catch((error) => {
        console.log(error)
        this.inputs = {}
        this.triggerAlert("error", error.message)
      })
  }

  // change password handler
  forgotPasswordSubmit = (evt) => {
    evt.preventDefault();
    const username = this.inputs.username;
    const code = this.inputs.code;
    const newPassword = this.inputs.newpassword;
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then((data) => {
        console.log(data)
        this.triggerAlert("success", "you've successfully changed your password, please sign in", {
          redirect: false,
          signUp: false,
          forgotPassword: false,
          forgotPasswordSubmit: false,
          confirmSignUp: false
        })
        this.inputs = {}
      })
      .catch((error) => {
        console.log("Failed to submit new password to forgot password submit")
        console.log(error)
        this.inputs = {}
        this.triggerAlert("error", error.message)
      })
  }

  // input change handler
  handleInputChange = (evt) => {
    this.inputs = this.inputs || {};
    const { name, value, type, checked } = evt.target;
    const check_type = ["radio", "checkbox"].includes(type);
    this.inputs[name] = check_type ? checked : value;
    this.inputs["checkedValue"] = check_type ? value : null;
    this._isMounted ? this.setState({ error: "" }) : this.noop();
  }

  // close handler for Snackbar (alert display)
  handleClose = () => {
    this._isMounted ? this.setState({ displayAlert: false }) : this.noop();
  }

  // helper function to display alerts
  // also takes extra object that will set other properties in state to avoid calling setState multiple times
  triggerAlert = (severity, message, extra={}) => {
    let base = {
      [severity]: message,
      severity: severity,
      displayAlert: true
    }
    let merged = {...base,...extra}
    // always check if componant is mounted before calling setState to avoid errors
    // thrown if you try to call setState after componant is already unmounted
    this._isMounted ? this.setState(merged) : this.noop()
  }

  // helper to send messages into MuiAlert componant (used for displaying alerts to user)
  Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // redirect to chat after user successfully authenticates
  renderRedirect = () => {
    if (this.props.isUserSignedIn) {
      return <Redirect to="/" />
    }
  }

  // toggle signUp state
  toggleSignUp = () => {
    this._isMounted ? this.setState({ signUp: !this.state.signUp }) : this.noop();
  }

  // toggle forgotPassword state
  toggleForgotPassword = () => {
    this._isMounted ? this.setState({ forgotPassword: !this.state.forgotPassword }) : this.noop();
  }

  // toggle forgotPasswordSubmit state
  toggleForgotPasswordSubmit = () => {
    this._isMounted ? this.setState({ forgotPasswordSubmit: !this.state.forgotPasswordSubmit }) : this.noop();
  }

  // reset UI related state values so the default sign in appears
  backToSignIn = () => {
    this._isMounted ? this.setState(
      {
        redirect: false,
        signUp: false,
        forgotPassword: false,
        forgotPasswordSubmit: false,
        confirmSignUp: false
      }
    ) : this.noop();
  }

  // render componants needed for signup
  displaySignInSignUp = (classes) => {
    return (
      <React.Fragment>
        <Typography component="h1" variant="h5" color="textSecondary">
          {
            (this.state.signUp)
            ? "Create an Account"
            : "Sign in to your Account"
          }
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            color="secondary"
            margin="normal"
            required
            fullWidth
            value={this.inputs.username || ''}
            id="username"
            label="Username"
            name="username"
            autoComplete="email"
            placeholder={!this.inputs.username ? "Enter your username" : ""}
            autoFocus
            onChange={this.handleInputChange}
          />
          <TextField
            variant="outlined"
            color="secondary"
            margin="normal"
            required
            fullWidth
            value={this.inputs.password || ''}
            name="password"
            label="Password"
            type="password"
            id="password"
            placeholder={!this.inputs.password ? "Enter your password" : ""}
            autoComplete="current-password"
            onChange={this.handleInputChange}
          />
          {
            (this.state.signUp)
            ? (
              <React.Fragment>
                <TextField
                  variant="outlined"
                  color="secondary"
                  margin="normal"
                  required
                  fullWidth
                  value={this.inputs.email || ''}
                  name="email"
                  label="Email Address"
                  type="email"
                  id="email"
                  placeholder={!this.inputs.email ? "Enter your Email Address" : ""}
                  onChange={this.handleInputChange}
                />
              </ React.Fragment>
            ) : (<React.Fragment></React.Fragment>)
          }
          <FormControlLabel
            control={<Checkbox value="remember" color="secondary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={this.handleFormSubmission}
          >
            {
              (this.state.signUp)
              ? "Create your Account"
              : "Sign In"
            }
          </Button>
          <Grid container>
            {
              (!this.state.signUp)
              ? (
                <React.Fragment>
                  <Grid item xs>
                    <Link href="#" variant="body2" onClick={this.toggleForgotPassword}>
                      Forgot password?
                    </Link>
                  </Grid>
                </React.Fragment>
              )
              : (
                <React.Fragment></React.Fragment>
              )
            }
            <Grid item>
              <Link href="#" variant="body2" onClick={this.toggleSignUp}>
                {
                  (this.state.signUp)
                  ? "Already have an account? Sign In"
                  : "Don't have an account? Sign Up"
                }
              </Link>
            </Grid>
          </Grid>
        </form>
      </React.Fragment>
    );
  }

  // render componants needed for confirming signup
  displayConfirmSignUp = (classes) => {
    return (
      <React.Fragment>
        <Typography component="h1" variant="h5" color="textSecondary">
          Verify your Email Address
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            color="secondary"
            margin="normal"
            required
            fullWidth
            value={this.inputs.username || ''}
            id="username"
            label="Username"
            name="username"
            autoComplete="email"
            autoFocus
            placeholder={!this.inputs.username ? "Enter your username" : ""}
            onChange={this.handleInputChange}
          />
          <TextField
            variant="outlined"
            color="secondary"
            margin="normal"
            required
            fullWidth
            value={this.inputs.code || ''}
            name="code"
            label="Code"
            id="code"
            autoComplete="current-password"
            placeholder={!this.inputs.code ? "Enter the code that was sent to your email" : ""}
            onChange={this.handleInputChange}
          />
          <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          onClick={this.handleFormSubmission}
          >
            Verify Email Address
          </Button>
          <Grid container>
            <Grid container item alignContent="flex-start" xs={8}>
              <Link href="#" variant="body2" onClick={this.resendConfirmationCode}>
                Resend Confirmation Code
              </Link>
            </Grid>
            <Grid container item justify="flex-end" xs={4}>
              <Link href="#" variant="body2" onClick={this.backToSignIn}>
                Back to Sign In
              </Link>
            </Grid>
          </Grid>
        </form>
      </React.Fragment>
    );
  }

  // render componants needed for forgot password
  displayForgotPassword = (classes) => {
    return (
      <React.Fragment>
        <Typography component="h1" variant="h5" color="textSecondary">
          {"Reset your Password"}
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            color="secondary"
            margin="normal"
            required
            fullWidth
            value={this.inputs.username || ''}
            id="username"
            label="Username"
            name="username"
            autoComplete="email"
            placeholder={!this.inputs.username ? "Enter your username" : ""}
            autoFocus
            onChange={this.handleInputChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={this.forgotPassword}
          >
            Send Code
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={this.toggleForgotPassword}>
                Back to Sign In
              </Link>
            </Grid>
          </Grid>
        </form>
      </React.Fragment>
    );
  }

  // render componants needed for forgot password submission
  displayForgotPasswordSubmit = (classes) => {
    return (
      <React.Fragment>
        <Typography component="h1" variant="h5" color="textSecondary">
          {"Change your Password"}
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            color="secondary"
            margin="normal"
            required
            fullWidth
            value={this.inputs.username || ''}
            id="username"
            label="Username"
            name="username"
            placeholder={!this.inputs.username ? "Enter your username" : ""}
            autoFocus
            onChange={this.handleInputChange}
          />
          <TextField
            variant="outlined"
            color="secondary"
            margin="normal"
            required
            fullWidth
            value={this.inputs.code || ''}
            id="code"
            label="Code"
            name="code"
            placeholder={!this.inputs.code ? "Enter the code that was sent to your email" : ""}
            onChange={this.handleInputChange}
          />
          <TextField
            variant="outlined"
            color="secondary"
            margin="normal"
            required
            fullWidth
            value={this.inputs.newpassword || ''}
            name="newpassword"
            label="New Password"
            type="password"
            id="newpassword"
            placeholder={!this.inputs.newpassword ? "Enter your New Password" : ""}
            onChange={this.handleInputChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={this.forgotPasswordSubmit}
          >
            Reset your Password
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={this.toggleForgotPasswordSubmit}>
                Back to Sign In
              </Link>
            </Grid>
          </Grid>
        </form>
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props
    return (
      <Container component="main" maxWidth="xs">
        {this.renderRedirect()}
        <CssBaseline />
        <div className={classes.paper}>
          <Snackbar open={this.state.displayAlert} autoHideDuration={5000} onClose={this.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <this.Alert onClose={this.handleClose} severity={this.state.severity}>
              {this.state.severity === "success" ? this.state.success : this.state.error}
            </this.Alert>
          </Snackbar>
          <Avatar className={classes.avatar} src={aveqLogo}/>
          {(() => {
            if (this.state.forgotPasswordSubmit) {
              return this.displayForgotPasswordSubmit(classes);
            } else if (this.state.forgotPassword) {
              return this.displayForgotPassword(classes);
            } else if (this.state.confirmSignUp) {
              return this.displayConfirmSignUp(classes);
            } else {
              return this.displaySignInSignUp(classes);
            }
          })()}
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
  isUserSignedIn: state.user.isUserSignedIn,
});

export default connect(
  mapStateToProps,
  { fetchUserDetails, getUserToken }
)(withStyles(useStyles)(AveQSignIn));
