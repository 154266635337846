import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import reduxWebsocket from "@giantmachines/redux-websocket";
import { asyncDispatchMiddleware } from "./asynActionMiddleware";

const initialState = {};

// Create the middleware instance.
const reduxWebsocketMiddleware = reduxWebsocket({ prefix: "OCTOPUS" });

const middleWare = [thunk, reduxWebsocketMiddleware, asyncDispatchMiddleware];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancer(applyMiddleware(...middleWare))
);

export default store;
