import {
  SET_AUTHORIZED_STATIONS, RESET_STATION, SET_STATION_UNREAD, SET_STATION_PROFILE, SET_STATION_ACTIONABLES,
  SET_STATION_PLAYLISTS, EDIT_PROFILE, UPDATE_PROFILE, SAVE_PROFILE, CANCEL_PROFILE,
  STATION_ERROR, RESET_STATION_ERROR, UPDATE_ACTIONABLE_TEMPLATE, UPDATE_TRIGGER, UPDATE_ACT_CODE
} from "../actions/types"
import { updateProperties, updateActionable, addUserAction, updateMessageTemplate,
  updateTriggerOption, updateActionableCode } from "../actions/stationActions"

const initialState = {
  currentStationId: 0,
  list: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHORIZED_STATIONS:
      let stations = {}
      action.payload.forEach((s) => stations[s.station.station_id] = {key: s.station.station_id, station: s.station})
      return  Object.assign({}, state, {
        list: stations
      })

    case RESET_STATION:
      stations = Object.assign({}, state.list)
      Object.keys(stations).forEach(k => {
        let s = stations[k]
        if (s.sessionId)
          delete s.sessionId
        if (s.profile)
          delete s.profile
      })

      return {
        currentStationId: 0,
        list: stations
      }

    case SET_STATION_UNREAD:
      stations = Object.assign({}, state.list)
      let stationId = action.payload.station_id
      stations[stationId].unread = stations[stationId].unread? stations[stationId].unread + 1 : 1
      return  Object.assign({}, state, {
        list: stations
      })

    case SET_STATION_PROFILE:
      stations = Object.assign({}, state.list)
      const currentStationId = action.payload.stationId
      stations[currentStationId].sessionId = action.payload.sessionId
      stations[currentStationId].profile = { value: { properties: action.payload.properties } }
      return {
        currentStationId: currentStationId,
        list: stations
      }

    case SET_STATION_ACTIONABLES:
      stations = Object.assign({}, state.list)
      let currentStation = stations[state.currentStationId]
      currentStation.profile.value.actionables = action.payload.filter(i => i.type === 'entity.actionableTemplate')
      currentStation.profile.value.actionOptions = action.payload.filter(i => i.type === 'entity.action')
      currentStation.profile.value.triggerOptions = action.payload.filter(i => i.type === 'entity.trigger')
      currentStation.profile.value.userActions = action.payload.filter(i => i.type === 'entity.userAction')
      currentStation.profile.value.messageTemplates = action.payload.filter(i => i.type === 'entity.messageTemplate')
      currentStation.profile.value.actionableCodes = action.payload.filter(i => i.type === 'entity.actionableCode')

      return Object.assign({}, state, {
        list: stations
      })

    case SET_STATION_PLAYLISTS:
      stations = Object.assign({}, state.list)
      currentStation = stations[state.currentStationId]
      currentStation.profile.value.playlists = action.payload

      return Object.assign({}, state, {
        list: stations
      })

    case EDIT_PROFILE:
      stations = Object.assign({}, state.list)
      currentStation = stations[state.currentStationId]
      if (typeof (currentStation.profile.staging) !== 'undefined')
        return Object.assign({}, state)

      currentStation.profile.staging = { value: Object.assign({}, currentStation.profile.value) }

      return Object.assign({}, state, {
        list: stations
      })

    case UPDATE_PROFILE:
      stations = Object.assign({}, state.list)
      currentStation = stations[state.currentStationId]
      let staging = currentStation.profile.staging
      staging.value[action.payload.section] = action.payload.value
      staging.data = action.payload.data

      return Object.assign({}, state, {
        list: stations
      })

    case SAVE_PROFILE:
      stations = Object.assign({}, state.list)
      currentStation = stations[state.currentStationId]
      staging = currentStation.profile.staging
      staging.value[action.payload.section] = action.payload.value
      currentStation.profile.value = staging.value

      delete currentStation.profile.staging

      // TODO submit updated value of section
      switch (action.payload.section) {
        case 'properties':
          updateProperties(action.asyncDispatch, currentStation.profile.value.properties, currentStation.sessionId)
          break

        case 'actionables':
          let contentId = action.payload.contentId
          let matched = currentStation.profile.value.actionables.filter(a => a.contentId === contentId)
          updateActionable(action.asyncDispatch, matched ? matched[0] : action.payload, currentStation.sessionId)
          break

        case 'triggerOptions':
          contentId = action.payload.contentId
          matched = currentStation.profile.value.triggerOptions.filter(a => a.id === contentId)
          if (matched)
            updateTriggerOption(action.asyncDispatch, matched[0], currentStation.sessionId)
          break

        case 'actionableCodes':
          contentId = action.payload.contentId
          matched = currentStation.profile.value.actionableCodes.filter(a => a.id === contentId)
          if (matched)
            updateActionableCode(action.asyncDispatch, matched[0], currentStation.sessionId)
          break

        case 'userActions':
          contentId = action.payload.contentId
          matched = currentStation.profile.value.userActions.filter(a => a.id === contentId)
          if (matched)
            addUserAction(action.asyncDispatch, matched[0], action.payload.isNew, currentStation.sessionId)
          break

        case 'messageTemplates':
          contentId = action.payload.contentId
          matched = currentStation.profile.value.messageTemplates.filter(a => a.contentId === contentId)
          if (matched)
            updateMessageTemplate(action.asyncDispatch, matched[0], currentStation.sessionId)
          break

        default:
          break
      }

      return Object.assign({}, state, {
        list: stations
      })

      case UPDATE_ACTIONABLE_TEMPLATE:
        stations = Object.assign({}, state.list)
        currentStation = stations[state.currentStationId]
        currentStation.profile.value.actionables = currentStation.profile.value.actionables.map(
          a => a.contentId === action.payload.contentId ? action.payload.actionableTemplate : a
        )

        return Object.assign({}, state, {
          list: stations
        })

      case UPDATE_TRIGGER:
        stations = Object.assign({}, state.list)
        currentStation = stations[state.currentStationId]
        currentStation.profile.value.triggerOptions = currentStation.profile.value.triggerOptions.map(
          a => a.id === action.payload.id ? action.payload.triggerOption : a
        )

        return Object.assign({}, state, {
          list: stations
        })

      case UPDATE_ACT_CODE:
        stations = Object.assign({}, state.list)
        currentStation = stations[state.currentStationId]
        currentStation.profile.value.actionableCodes = currentStation.profile.value.actionableCodes.map(
          a => a.id === action.payload.id ? action.payload.actionableCode : a
        )

        return Object.assign({}, state, {
          list: stations
        })

      case CANCEL_PROFILE:
        stations = Object.assign({}, state.list)
        currentStation = stations[state.currentStationId]

        if (typeof (currentStation.profile.staging) !== 'undefined')
          delete currentStation.profile.staging

        return Object.assign({}, state, {
          list: stations
        })

      case STATION_ERROR:
        stations = Object.assign({}, state.list)
        currentStation = stations[state.currentStationId]
        currentStation.error = { timestamp: Date.now(), error: action.payload }

        return Object.assign({}, state, {
          list: stations
        })

      case RESET_STATION_ERROR:
        stations = Object.assign({}, state.list)
        currentStation = stations[state.currentStationId]
        currentStation.error = { timestamp: Date.now(), error: null }

        return Object.assign({}, state, {
          list: stations
        })

    default:
      return state
  }
}
