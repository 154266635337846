export const percentOfWindowHeight = (percent, offset) => {
  return (percent / 100) * (window.innerHeight - offset);
}

export const percentOfWindowWidth = (percent, offset) => {
  return (percent / 100) * (window.innerWidth - offset);
}

export const truncateStr = (str, length = 100) => {
    const ending = ' ...(truncated)'
    return (str.length > length) ? str.substring(0, length - ending.length) + ending : str
}

export const randomStr = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

export const requiredInputValidator = (input) => input ? undefined : `Input is required`

export const emptyInputValidator = (input) => input.trim() === "" ? `Input cannot be empty` : undefined

export const substitute = (text, props) => {
  if (!text) return text

  const regex1 = /\$\{((\w+:)?(\w[\w|-]+))\}/g;

  let matched;
  let result = []
  let start = 0

  while ((matched = regex1.exec(text)) !== null) {
    result.push(text.substring(start, regex1.lastIndex - matched[0].length))
    result.push(matched[1] in props ? props[matched[1]] : matched[0])
    start = regex1.lastIndex
  }
  result.push(text.substring(start))
  return result.join('')
}

export const dateWithNoTime = (dt = new Date()) => {
  let d = new Date(dt)
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

export const formatDate = (d) => {
  const padding = (mt) => (mt >= 10 ? `${mt}` : `0${mt}`)
  return `${d.getFullYear()}-${padding(d.getMonth() + 1)}-${padding(d.getDate())} ${padding(d.getHours())}:${padding(d.getMinutes())}:${padding(d.getSeconds())}`
}
