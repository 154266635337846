import { CHANGE_VIEW, CLEAR_VIEW } from "./types"

const routingTable = {
  'station-selector': {viewUri: 'station-selector', menu: false},
  'channel-selector': {viewUri: 'channel-selector', menu: true},
  'channel-selector-only': {viewUri: 'channel-selector-only', menu: true},
  'actionable-template-view': {viewUri: 'actionable-template-view', menu: true},
  'actionable-template-edit': {viewUri: 'actionable-template-edit', menu: true},
  'trigger-view': {viewUri: 'trigger-view', menu: true},
  'trigger-edit': {viewUri: 'trigger-edit', menu: true},
  'act-code-view': {viewUri: 'act-code-view', menu: true},
  'act-code-edit': {viewUri: 'act-code-edit', menu: true},
  'station-properties-view': {viewUri: 'station-properties-view', menu: true},
  'station-properties-edit': {viewUri: 'station-properties-edit', menu: true},
  'playlist-view': {viewUri: 'playlist-view', menu: true},
  'playlist-edit': {viewUri: 'playlist-edit', menu: true},
  'user-action-view': {viewUri: 'user-action-view', menu: true},
  'user-action-edit': {viewUri: 'user-action-edit', menu: true},
  'message-template-view': {viewUri: 'message-template-view', menu: true},
  'message-template-edit': {viewUri: 'message-template-edit', menu: true},
}

export const changeView = (dispatch, viewId, props) => {
  const viewInfo = routingTable[viewId]
  dispatch({
    type: CHANGE_VIEW,
    payload: {...viewInfo, ...props}
  })
}

export const clearView = (dispatch) => {
  dispatch({
    type: CLEAR_VIEW,
  })
}
